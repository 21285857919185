<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>全员营销</el-breadcrumb-item>
            <el-breadcrumb-item>营销产品</el-breadcrumb-item>
            <el-breadcrumb-item>添加营销产品</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="pre-sale-main">
         <el-row class="right-form">
            <el-form ref="formData" :model="formData" :rules="rules" label-width="110px" @submit.native.prevent>
               <el-form-item label="营销名称" prop="name">
                  <el-input v-model="formData.name" style="width: 40%"></el-input>
               </el-form-item>

               <el-form-item label="营销类型">
                  <el-radio-group v-model="formData.type" @change="getgoodsinfo" class="radio-box">
                     <el-radio-button v-for="(item,index) in marketingTypelist" :key="index" :value="item.id" :disabled="disabled"
                                      :label="item.id">{{ item.name }}
                     </el-radio-button>
                  </el-radio-group>
               </el-form-item>

               <el-form-item label="营销内容" v-if="formData.type !='4' ">
                  <el-radio-group v-model="formData.allSalesProduct" @change="changetype">
                     <template>
                        <el-radio label="YES" :disabled="disabled">所有{{ formData.type | goodsTypeState }}</el-radio>
                        <el-radio label="NO" :disabled="disabled">指定{{ formData.type | goodsTypeState }}</el-radio>
                        <el-button v-if="formData.allSalesProduct == 'NO' && this.$route.query.action == 'add' "
                                   @click="dialogVisible = true" class="bg-gradient" type="primary" :disabled="disabled">
                           选择{{ formData.type | goodsTypeState }}
                        </el-button>
                        <el-button v-if="formData.allSalesProduct == 'NO' && this.$route.query.action == 'edit'"
                                   @click="emidtdialogVisible = true" class="bg-gradient" type="primary" :disabled="disabled">
                           选择{{ formData.type | goodsTypeState }}
                        </el-button>
                     </template>
                  </el-radio-group>
                  <!--房型-->
                  <el-form-item label="" v-if="formData.type == 'HOTEL' && goodsinfo.length !=0  " style="width: 80%">
                     <div class="table-box">
                        <div class="table-item">名称</div>
                        <div class="table-item">单价</div>
                        <div class="table-item" >操作</div>
                     </div>
                     <!--编辑时-->
                     <div class="table-box" v-for="(item,index) in goodsinfo" :key="index">
                        <div class="table-item">{{ item.roomTypeName }}</div>
                        <div class="table-item" style="color: red">¥{{ item.roomTypePrice }}</div>
                        <div class="table-item" v-if="$route.query.action == 'add' ">
                           <el-button type="text" @click="deleterow(item,index)" :disabled="disabled">删除</el-button>
                        </div>
                     </div>
                  </el-form-item>

                  <!--预售券和商品-->
                  <el-form-item label=""
                                v-if="(formData.type == 'GOODS' || formData.type == 'COUPON_PRESELL') && goodsinfo.length!= 0 "
                                style="width: 80%">
                     <div class="table-box">
                        <div class="table-item" style="width: 25%" v-if="formData.type == 'GOODS' ">商品分类</div>
                        <div class="table-item" style="width: 25%" v-if="formData.type == 'GOODS' ">商品名称</div>
                        <div class="table-item" style="width: 25%" v-if="formData.type == 'COUPON_PRESELL' ">预售券名称</div>
                        <div class="table-item" style="width: 25%" v-if="formData.type == 'COUPON_PRESELL' ">预售商品</div>
                        <div class="table-item" style="width: 25%">单价</div>
                        <div class="table-item" style="width: 25%" >操作</div>
                     </div>
                     <!--编辑时-->
                     <div class="table-box" v-for="(item,index) in goodsinfo" :key="index">
                        <!--商品-->
                        <div class="table-item" style="width: 25%" v-if="formData.type == 'GOODS'">
                           {{item.categoryName || '未分类'}}
                        </div>
                        <div class="table-item" style="width: 25%" v-if="formData.type == 'COUPON_PRESELL' ">
                           {{item.mainTitle }}
                        </div>
                        <div class="table-item goods-title" style="width: 25%;margin: 0;padding: 0" v-if="formData.type == 'GOODS'">
                           {{item.title}}
                        </div>
                        <div class="table-item goods-title" style="width: 25%;margin: 0;padding: 0" v-if="formData.type == 'COUPON_PRESELL'">
                           {{item.couponPresellProduct.productName }}
                        </div>
                        <div class="table-item" style="color: red;width: 25%" v-if="formData.type == 'GOODS'" >
                           ¥{{item.price }}
                        </div>
                        <div class="table-item" style="color: red;width: 25%" v-if="formData.type == 'COUPON_PRESELL' &&  pagetype== 'add' ">
                           ¥{{item.presellPrice/100}}
                        </div>
                        <div class="table-item" style="color: red;width: 25%" v-if="formData.type == 'COUPON_PRESELL' && pagetype == 'edit' ">
                           ¥{{item.presellPrice}}
                        </div>
                        <div class="table-item" style="width: 25%" >
                           <el-button type="text" @click="deleterow(item,index)" :disabled="disabled">删除</el-button>
                        </div>
                     </div>
                  </el-form-item>
               </el-form-item>

               <el-form-item label="参与分佣">
                  <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                     <el-checkbox v-for="(item,index) in cities" :label="item.id" :key="index">
                        {{ item.name }}
                     </el-checkbox>
                  </el-checkbox-group>
               </el-form-item>

               <el-form-item label="奖励规则" prop="marketPrice">
                  <el-button type="primary" class="bg-gradient" @click="marketingdig">添加营销分组</el-button>
                  <el-button type="text" style="margin-left: 30px" @click="jump">管理分组</el-button>
               </el-form-item>

               <el-form-item label="" style="width:60%">
                  <el-table  ref="mainTable"
                             border
                             :data="couponData"
                             :stripe="true"
                  >
                     <el-table-column label="序号" width="60">
                        <template slot-scope="scope">
                           {{ scope.$index + 1 }}
                        </template>
                     </el-table-column>
                     <el-table-column prop="groupName" label="分组名称" width="120"/>
                     <el-table-column prop="productName" align="center" label="直推人奖励"
                                      v-if=" checkedCities.indexOf('1') != -1 ">
                        <el-table-column prop="productName" width="180" label="奖励金额" style="display: flex">
                           <template slot-scope="scope">
                              <input class="textinpt" @change='leaveberChange1(scope.row.awardAmount1,scope.$index,scope.row.isPercent1)'
                                     v-model="scope.row.awardAmount1"
                                     onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                                     v-if="scope.row.isPercent1 == 'YES' "
                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"/>
                              <input class="textinpt" @change='leaveberChange1(scope.row.awardAmount1,scope.$index,scope.row.isPercent1)'
                                     v-model="scope.row.awardAmount1"
                                     v-else
                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"/>

                              <el-select v-model="scope.row.isPercent1" size="mini" @change="changeunit(scope.row.isPercent1,scope.$index,'isPercent1')" style="float: right;width: 60px">
                                 <el-option label="元" value="NO"></el-option>
                                 <el-option label="%" value="YES"></el-option>
                              </el-select>
                           </template>
                        </el-table-column>
<!--                        <el-table-column prop="productName" width="120" label="奖励积分">-->
<!--                           <template slot-scope="scope">-->
<!--                              <input class="textinpt" onkeyup="this.value=this.value.replace(/[^\d]/g,'') "-->
<!--                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"-->
<!--                                     v-model="scope.row.awardIntegral1"/>-->
<!--                           </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column prop="productName" width="200" label="奖励券">-->
<!--                           <template slot-scope="scope">-->
<!--                              <div v-if="couponlistshow">-->
<!--                                 <p v-for="(item,index) in scope.row.couponlist" :key="index">-->
<!--                                    {{ item.couponName || item.mainTitle }}({{ item.count }}张) <el-button type="text" style="color: red" @click="deletercoupon(scope.row.couponlist,item,index)">删除</el-button></p>-->
<!--                              </div>-->
<!--                              <el-button type="text" @click="getIndex(scope.row,scope.$index,'一级')">选择</el-button>-->
<!--                           </template>-->
<!--                        </el-table-column>-->
                     </el-table-column>
                     <el-table-column prop="productName" align="center" width="140" label="间推人奖励"
                                      v-if=" checkedCities.indexOf('2') != -1 ">
                        <el-table-column prop="productName" width="180" label="奖励金额">
                           <template slot-scope="scope">
                              <input class="textinpt" v-model="scope.row.awardAmount2"
                                     onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                                     v-if="scope.row.isPercent2 == 'YES' "
                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"
                                     @change='leaveberChange2(scope.row.awardAmount2,scope.$index,scope.row.isPercent2)'/>
                              <input class="textinpt" @change='leaveberChange2(scope.row.awardAmount2,scope.$index,scope.row.isPercent2)'
                                     v-model="scope.row.awardAmount2"
                                     v-else
                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"/>
                              <el-select v-model="scope.row.isPercent2" size="mini" @change="changeunit(scope.row.isPercent2,scope.$index,'isPercent2')" style="float: right;width: 60px">
                                 <el-option label="元" value="NO"></el-option>
                                 <el-option label="%" value="YES"></el-option>
                              </el-select>
                           </template>
                        </el-table-column>
<!--                        <el-table-column prop="productName" width="120" label="奖励积分">-->
<!--                           <template slot-scope="scope">-->
<!--                              <input class="textinpt" onkeyup="this.value=this.value.replace(/[^\d]/g,'') "-->
<!--                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"-->
<!--                                     v-model="scope.row.awardIntegral2"/>-->
<!--                           </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column prop="productName" width="200" label="奖励券">-->
<!--                           <template slot-scope="scope">-->
<!--                              <div v-if="couponlistshow1">-->
<!--                                 <p v-for="(item,index) in scope.row.couponlist1" :key="index">-->
<!--                                    {{ item.couponName || item.mainTitle }}({{ item.count }}张) <el-button type="text" style="color: red" @click="deletercoupon(scope.row.couponlist1,item,index)">删除</el-button></p>-->
<!--                              </div>-->
<!--                              <el-button type="text" @click="getIndex(scope.row,scope.$index,'二级')">选择</el-button>-->
<!--                           </template>-->
<!--                        </el-table-column>-->
                     </el-table-column>
                     <el-table-column prop="productName" align="center" label="购买人奖励"
                                      v-if=" checkedCities.indexOf('3') != -1 ">
                        <el-table-column prop="productName" width="180" label="奖励金额">
                           <template slot-scope="scope">
                              <input class="textinpt" onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"
                                     v-if="scope.row.isPercentReferrer == 'YES' "
                                     @change='leaveberChange3(scope.row.awardAmountReferrer,scope.$index,scope.row.isPercentReferrer)'
                                     v-model="scope.row.awardAmountReferrer"/>
                              <input class="textinpt" @change='leaveberChange3(scope.row.awardAmountReferrer,scope.$index,scope.row.isPercentReferrer)'
                                     v-model="scope.row.awardAmountReferrer"
                                     v-else
                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"/>
                              <el-select v-model="scope.row.isPercentReferrer" size="mini" @change="changeunit(scope.row.isPercentReferrer,scope.$index,'isPercentReferrer')" style="float: right;width: 60px">
                                 <el-option label="元" value="NO"></el-option>
                                 <el-option label="%" value="YES"></el-option>
                              </el-select>
                           </template>

                        </el-table-column>
<!--                        <el-table-column prop="productName" width="120" label="奖励积分">-->
<!--                           <template slot-scope="scope">-->
<!--                              <input class="textinpt" onkeyup="this.value=this.value.replace(/[^\d]/g,'') "-->
<!--                                     onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"-->
<!--                                     v-model="scope.row.awardIntegralReferrer"/>-->
<!--                           </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column prop="productName" width="200" label="奖励券">-->
<!--                           <template slot-scope="scope">-->
<!--                              <div v-if="couponlistshow2">-->
<!--                                 <p v-for="(item,index) in scope.row.couponlist2" :key="index">-->
<!--                                    {{ item.couponName || item.mainTitle }}({{ item.count }}张) <el-button type="text" style="color: red" @click="deletercoupon(scope.row.couponlist2,item,index)">删除</el-button></p>-->
<!--                              </div>-->
<!--                              <el-button type="text" @click="getIndex(scope.row,scope.$index,'被推荐')">选择</el-button>-->
<!--                           </template>-->
<!--                        </el-table-column>-->
                     </el-table-column>
                     <!--               <el-table-column prop="quantity" label="每人限推人数">-->
                     <!--                  <template slot-scope="scope">-->
                     <!--                     <input class="textinpt" v-model="scope.row.restrictNum" />人-->
                     <!--                  </template>-->
                     <!--               </el-table-column>-->
                     <el-table-column prop="presellPrice"  label="操作">
                        <template slot-scope="scope">
                           <el-button type="text" @click="deltablerow(scope.row,scope.$index)">删除</el-button>
                        </template>
                     </el-table-column>

                  </el-table>
               </el-form-item>

               <el-form-item label="分销时段" prop="activityEndTime">
                  <p class="tips">指定时间内完成的订单或任务才能获得对应的分佣。</p>
                  <el-radio v-model="formData.timeType" label="EVERY_DAY">每天</el-radio>
                  <p>
                     <el-radio v-model="formData.timeType" label="DATE_RANGE">指定时间范围</el-radio>
                     <el-date-picker
                           v-model="distributionTime"
                           v-if="formData.timeType == 'DATE_RANGE' "
                           class="width-300 m-right-10"
                           @change="choosetime()"
                           :picker-options="pickerOptions"
                           type="daterange"
                           range-separator="至"
                           start-placeholder="开始日期"
                           end-placeholder="结束日期"
                           value-format="yyyy-MM-dd">
                     </el-date-picker>
                  </p>

               </el-form-item>
               <el-form-item label="海报封面" >
                  <p class="tips">用于用户分享时，生成海报使用。</p>
                  <el-row class="flex-pic" v-if="isRefresh">
                     <img width="148" height="148" class="old-pic" v-if="isEcho" :src="formData.image" alt="">
                     <el-upload
                           ref="posterupload"
                           :limit="1"
                           :show-file-list="true"
                           action="#"
                           :auto-upload="false"
                           list-type="picture-card"
                           :on-change="handleChangeposter"
                           :on-preview="handlePictureCardPreview">
                        <i class="el-icon-plus"></i>
                     </el-upload>
                     <el-dialog title="查看图片" :visible.sync="imgdialogVisible">
                        <img width="100%" :src="formData.image" alt="">
                     </el-dialog>
                  </el-row>


               </el-form-item>
<!--               <el-form-item label="营销文案">-->
<!--                  <el-input class="width-300" v-model="formData.content" type="textarea"></el-input>-->
<!--               </el-form-item>-->
               <el-form-item label="营销设置">
                  <el-checkbox v-model="formData.isSeckillCommission" aria-checked="true">
                     秒杀提佣
                  </el-checkbox>
                  <span class="tips" style="margin-left: 20px">订单为秒杀订单时是否给推荐人分佣。</span>
               </el-form-item>
               <el-form-item label="">
                  <el-button class="width-120 bg-gradient" type="primary" @click="submit()">提交</el-button>
               </el-form-item>
            </el-form>
         </el-row>
      </el-row>


      <!--选择营销内容弹窗-->
      <el-dialog  :visible.sync="dialogVisible" width="50%" >
         <div slot="title" class="header-title">
            <span class="title-text">选择{{ formData.type | goodsTypeState }}</span>
         </div>
         <el-row class="search-box" v-if="formData.type == 'GOODS' ">
            <el-row class="search-row">
               <!-- 推广订单搜索栏 -->
               <el-row class="search-item">
                  <label>商品名称：</label>
                  <el-input class="width-175 m-left-5" v-model="title"></el-input>
                  <el-button style="margin: 10px 30px;width: 98px;" @click="getGoodslist" class="bg-gradient">查询</el-button>
                  <el-button style="margin: 10px 0px;width: 98px;" @click="restgoodlist" class="bg-gradient">重置 </el-button>
               </el-row>

            </el-row>
         </el-row>
         <el-table :data="marketingcontentlist" height="300" highlight-current-rowref="orderTable" ref="dataTable"  tooltip-effect="light" @selection-change="getCurrentRow" >
            <!--房型-->
            <el-table-column key="1" label="选择" type="selection" :selectable="selectable" prop="editshow" width="60"/>
            <el-table-column key="2" center prop="roomTotalNum" label="封面" v-if="formData.type == 'HOTEL' ">
               <template slot-scope="scope">
                  <img class="table-img" v-if="scope.row.pmsRoomTypeImages.length != 0 "
                       :src="scope.row.pmsRoomTypeImages[0].imageUrl"/>
               </template>
            </el-table-column>

            <el-table-column key="3" prop="roomTypeName" label="名称" v-if="formData.type == 'HOTEL' ">
               <template slot-scope="scope">
                  {{ scope.row.roomTypeName }}
               </template>
            </el-table-column>

            <el-table-column key="4" prop="roomTypePrice" label="房间价格" v-if="formData.type == 'HOTEL' ">
               <template slot-scope="scope">
                  ¥{{ scope.row.roomTypePrice }}
               </template>
            </el-table-column>

            <!--商品-->

            <el-table-column key="2" center prop="roomTotalNum" width="300" label="商品图片/名称" v-if="formData.type == 'GOODS' ">
               <template slot-scope="scope" v-if="formData.type == 'GOODS' ">
                  <div class="table-box">
                     <img class="table-img" v-if="scope.row.coverPath !='' " :src="scope.row.coverPath"/><span
                        class="goods-title">{{ scope.row.title }}</span>
                  </div>

               </template>
            </el-table-column>
            <el-table-column key="3" prop="quantity" label="库存" v-if="formData.type == 'GOODS' ">
               <template slot-scope="scope">
                  ¥{{ scope.row.quantity }}
               </template>
            </el-table-column>

            <el-table-column key="4" prop="price" label="价格" v-if="formData.type == 'GOODS' ">
               <template slot-scope="scope">
                  ¥{{ scope.row.price }}
               </template>
            </el-table-column>

            <!--预售券-->
            <el-table-column key="2" center label="预售券名称" v-if="formData.type == 'COUPON_PRESELL' ">
               <template slot-scope="scope">
                  {{ scope.row.mainTitle }}
               </template>
            </el-table-column>

            <el-table-column key="3" label="预售商品" v-if="formData.type == 'COUPON_PRESELL' ">
               <template slot-scope="scope">
                  {{ scope.row.couponPresellProduct.productName }}
               </template>
            </el-table-column>

            <el-table-column key="4" label="包含商品数量" v-if="formData.type == 'COUPON_PRESELL' ">
               <template slot-scope="scope">
                  {{ scope.row.couponPresellProduct.quantity }}
               </template>
            </el-table-column>

            <el-table-column key="5" label="预售券价格" v-if="formData.type == 'COUPON_PRESELL' ">
               <template slot-scope="scope">
                  ¥{{ scope.row.presellPrice / 100 }}
               </template>
            </el-table-column>
         </el-table>

         <div class="dialog-bottombox">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="determinecontent">确 定</el-button>
         </div>
      </el-dialog>
      <!--编辑时  选择营销内容弹窗-->
      <el-dialog
            :visible.sync="emidtdialogVisible"
            width="50%"
      >
         <div slot="title" class="header-title">
            <span class="title-text">选择{{ formData.type | goodsTypeState }}</span>
         </div>
         <el-row class="search-box" v-if="formData.type == 'GOODS' ">
            <el-row class="search-row">
               <!-- 推广订单搜索栏 -->
               <el-row class="search-item">
                  <label>商品名称：</label>
                  <el-input class="width-175 m-left-5" v-model="title"></el-input>
                  <el-button style="margin: 10px 30px;width: 98px;" @click="getGoodslist" class="bg-gradient">查询</el-button>
                  <el-button style="margin: 10px 0px;width: 98px;"  @click="restgoodlist" class="bg-gradient">重置 </el-button>
               </el-row>

            </el-row>
         </el-row>
         <el-table :data="marketingcontentlist" height="300" highlight-current-rowref="orderTable" ref="dataTable" tooltip-effect="light">
            <!--房型-->
            <el-table-column label="选择" width="60">
               <template slot-scope="scope">
                  <el-radio :label="scope.$index+1" :disabled="scope.row.editshow" v-model="radio"
                            @change.native="getCurrentRow(scope.row)"></el-radio>
               </template>
            </el-table-column>
            <el-table-column key="2" center prop="roomTotalNum" label="封面" v-if="formData.type == 'HOTEL' ">
               <template slot-scope="scope">
                  <img class="table-img" v-if="scope.row.pmsRoomTypeImages.length != 0 "
                       :src="scope.row.pmsRoomTypeImages[0].imageUrl"/>
               </template>
            </el-table-column>

            <el-table-column key="3" prop="roomTypeName" label="名称" v-if="formData.type == 'HOTEL' ">
               <template slot-scope="scope">
                  {{ scope.row.roomTypeName }}
               </template>
            </el-table-column>

            <el-table-column key="4" prop="roomTypePrice" label="房间价格" v-if="formData.type == 'HOTEL' ">
               <template slot-scope="scope">
                  ¥{{ scope.row.roomTypePrice }}
               </template>
            </el-table-column>

            <!--商品-->

            <el-table-column key="2" center prop="roomTotalNum" width="300" label="商品图片/名称" v-if="formData.type == 'GOODS' ">
               <template slot-scope="scope" v-if="formData.type == 'GOODS' ">
                  <div class="table-box">
                     <img class="table-img" v-if="scope.row.coverPath !='' " :src="scope.row.coverPath"/><span
                        class="goods-title">{{ scope.row.title }}</span>
                  </div>

               </template>
            </el-table-column>

            <el-table-column key="3" prop="quantity" label="库存" v-if="formData.type == 'GOODS' ">
               <template slot-scope="scope">
                  ¥{{ scope.row.quantity }}
               </template>
            </el-table-column>

            <el-table-column key="4" prop="price" label="价格" v-if="formData.type == 'GOODS' ">
               <template slot-scope="scope">
                  ¥{{ scope.row.price }}
               </template>
            </el-table-column>

            <!--预售券-->
            <el-table-column key="1" center label="预售券名称" v-if="formData.type == 'COUPON_PRESELL' ">
               <template slot-scope="scope">
                  {{ scope.row.mainTitle }}
               </template>
            </el-table-column>

            <el-table-column key="2" prop="couponPresellProduct.productName" label="预售商品"
                             v-if="formData.type == 'COUPON_PRESELL' ">
               <template slot-scope="scope">
                  {{ scope.row.couponPresellProduct.productName || '' }}
               </template>
            </el-table-column>

            <el-table-column key="3" label="包含商品数量" v-if="formData.type == 'COUPON_PRESELL' ">
               <template slot-scope="scope">
                  {{ scope.row.couponPresellProduct.quantity || '' }}
               </template>
            </el-table-column>

            <el-table-column key="4" label="预售券价格" v-if="formData.type == 'COUPON_PRESELL' ">
               <template slot-scope="scope">
                  ¥{{ scope.row.presellPrice / 100 }}
               </template>
            </el-table-column>
         </el-table>

         <div class="dialog-bottombox">
            <el-button @click="emidtdialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="determinecontent">确 定</el-button>
         </div>
      </el-dialog>

      <!--选择营销分组弹窗-->
      <el-dialog :visible.sync="marketingdialogVisible" width="50%" >
         <div slot="title" class="header-title">
            <span class="title-text">选择营销分组</span>
         </div>
         <el-table :data="getmarketinggrouplist" height="280" highlight-current-rowref="orderTable" ref="groupTypedataTable" tooltip-effect="light" @selection-change="getaddCurrentRow"  >
            <el-table-column key="1" label="选择" type="selection" prop="editshow" width="60"/>
            <el-table-column key="2" center prop="groupName" label="分组名称"/>
            <el-table-column key="3" prop="groupType" label="分组类型">
               <template slot-scope="scope">
                  {{ scope.row.groupType | groupTypeState }}
               </template>
            </el-table-column>
         </el-table>
         <div class="dialog-bottombox">
            <el-button @click="marketingdialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="ensurerow">确 定</el-button>
         </div>
      </el-dialog>
      <el-dialog :visible.sync="marketingcouponslistdialog" width="50%">
         <div slot="title" class="header-title">
            <span class="title-text">选择优惠券</span>
         </div>
         <el-table :data="couponslist" highlight-current-rowref="orderTable" ref="couponslistdataTable"
                   tooltip-effect="light" @selection-change="getaddCouponRow">
            <el-table-column key="1" label="选择" type="selection" prop="editshow" width="60"/>
            <el-table-column key="2" center prop="mainTitle" label="优惠券名称"/>
            <el-table-column key="3" center prop="groupName" label="减免规则">
               <template slot-scope="scope">
                  <span v-if="scope.row.fixMoney">满{{ scope.row.useThreshold }}减{{ scope.row.fixMoney }}元</span>
                  <span v-if="scope.row.lowMoney">满{{ scope.row.useThreshold }}减{{ scope.row.lowMoney }}元</span>
               </template>
            </el-table-column>
            <el-table-column key="4" center prop="groupName" label="使用范围">
               <template slot-scope="scope">
                  <span>{{ scope.row.thresholdType == 'YES' ? "不限" : "指定用户" }}</span>
               </template>
            </el-table-column>
            <el-table-column key="5" prop="groupType" label="奖励张数">
               <template slot-scope="scope">
                  <el-input-number v-model="scope.row.count" :min="1" :max="scope.row.limitQuantity" size="small"/>
               </template>
            </el-table-column>
         </el-table>
         <div class="dialog-bottombox">
            <el-button @click="marketingcouponslistdialog = false">取 消</el-button>
            <el-button type="primary" @click="marketingcouponslistdialog = false">确 定</el-button>
         </div>
      </el-dialog>
   </section>
</template>

<script>
import { urlObj } from "@/api/interface";
import { marketing } from '@/api/interface/smtech'
import { pms } from "@/api/interface/pms";
import { coupon , preSale } from '@/api/interface/business'
import { CustomArray } from "@/common/js/common";
import { mapState } from 'vuex'
export default {
   data() {
      return {
         goodsinfo: [],//营销内容列表
         marketingTypelist: [ //营销类型列表
            {name: '订房', id: 'HOTEL'},
            {name: '商城商品', id: 'GOODS'},
            {name: '预售券', id: 'COUPON_PRESELL'},
            // {name:'注册拉新',id:'4' },
         ],
         goodslist: [],//商品列表
         checkgoodsinfo:[],//选中商品列表
         title: '',//商品名称
         dialogVisible: false,//营销内容弹窗
         emidtdialogVisible: false,//编辑时营销内容弹窗
         marketingdialogVisible: false,//营销分组弹窗
         marketingcouponslistdialog: false,//营销优惠券列表弹窗
         couponlistshow: false,//1奖励券重新渲染
         couponlistshow1: false,//2奖励券重新渲染
         couponlistshow2: false,//被奖励券重新渲染
         hotelId: '',
         radio: false,
         couponslist: [],//优惠券列表
         getmarketinggrouplist: [],//营销分组列表
         distributionTime: [],
         couponlist: [],//已选择的优惠券列表
         roomypelist: [],//房型列表
         PreSalelist: [],//预售券列表
         marketingcontentlist: [],//营销内容列表
         couponData: [],//奖励规则列表
         postershow: false,//海报重新渲染
         disabled:false,
         url: '',//接口地址
         imglist:[],
         pickerOptions: {
            disabledDate(time) {
               //如果没有后面的-8.64e7就是不可以选择今天的
               return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
            },
         },
         cities: [ //参与分佣列表
            {name: '直推人奖励', id: '1'},
            {name: '间推人奖励', id: '2'},
            {name: '购买人奖励', id: '3'},
         ],
         page: 1,
         pagetype:'',
         limit: 999,
         total: 0,
         isEcho: false,
         isRefresh:true,
         imgdialogVisible:false,
         groupinglist:[],
         roomTypeSource:'', //房型来源
         productsdetails: '',//商品详情
         marketinggoodslist: [],//已参加营销活动商品列表
         tableindex: '',//奖励规则列表下标
         awardtype: '',//奖励券类型
         checkedCities: ['1'],
         type: '',
         formData: {
            companyId: '',
            couponIds:[],
            awardRuleIds:[],
            commissionLevel: [],//参与分佣
            type: 'HOTEL',// 营销类型
            allSalesProduct: 'YES', //是否营销指定类型所有商品
            timeType: 'EVERY_DAY',//分销时间类型
            name: "",//标题
            products:[],//营销内容商品列表
            product:[],//营销内容全部商品
            image: '',//海报
            hotelId: '',
            id: '',//营销商品id
            awardRules: [],//奖励规则列表
            // limitQuantity:'',//可购买数量
            beginTime: "",//营销开始时间
            endTime: "",//营销结束时间
            isSeckillCommission: false, //是否秒杀提佣
            content: "",//备注
         },
         rules: {},
      }
   },

   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal.id) {
               this.hotelId = this.hotelInfo.id
               this.formData.hotelId = this.hotelInfo.id
               this.getmarketingproductslists()
               this.getroomtypelist()
               this.getGoodslist()
               this.getPreSalePage()
               this.getCoupons()
               this.getMarketinggroup()
            }
         },
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },

   filters: {
      goodsTypeState(val) {
         switch (val) {
            case 'HOTEL':
               return val = '房型'
            case 'GOODS':
               return val = '商品'
            case 'COUPON_PRESELL':
               return val = '预售券'
         }
      },
      groupTypeState(val) {
         switch (val) {
            case 'MEMBER_LEVE':
               return val = '会员等级'
            case 'DESIGNATED_MEMBERS':
               return val = '指定会员'

         }
      }
   },
   created() {
      this.pagetype = this.$route.query.action
      if(this.$route.query.action == 'edit'){
         this.disabled = true

      }
   },
   mounted() {

      this.formData.hotelId = this.hotelInfo.id
      this.formData.companyId = JSON.parse(sessionStorage.getItem('userInfo')).tenantId || ''
      this.formData.awardRules.marketingCoupons = []
      this.getmarketingproductslists()
      this.getCoupons()
      this.getMarketinggroup()

   },

   methods: {
      //获取营销商品详情
      getmarketingproductsdetails() {
         const url = marketing.getmarketingproductsdetails + this.$route.query.id
         const param = {}
         this.$axios.get(url, param).then(res => {
            if (res.success) {
               this.productsdetails = res.records
               this.formData.type = this.productsdetails.type
               this.formData.name = this.productsdetails.name
               this.formData.allSalesProduct = this.productsdetails.allSalesProduct
               this.checkedCities = []
               this.checkedCities = this.productsdetails.commissionLevel.split(',')
               this.formData.timeType = this.productsdetails.timeType
               if (this.productsdetails.timeType == 'DATE_RANGE'){
                  this.distributionTime=[this.productsdetails.beginTime,this.productsdetails.endTime]
               }
               if(this.productsdetails.image !=''){
                  this.formData.image = this.productsdetails.image
                  this.isEcho = true
               }
               this.formData.id = this.productsdetails.id
               if (this.productsdetails.allSalesProduct == 'NO'){
                  //房型
                  if (this.productsdetails.type == 'HOTEL') {
                     this.marketingcontentlist = this.roomypelist
                     this.goodsinfo = [{
                        'productId': this.productsdetails.productId,
                        'productName': this.productsdetails.productName,
                        'productPrice': this.productsdetails.productPrice,
                        'roomTypeName': this.productsdetails.productName,
                        'roomTypePrice': this.productsdetails.productPrice,
                     }]
                  }
                  //商品
                  if (this.productsdetails.type == 'GOODS') {
                     this.marketingcontentlist = this.goodslist
                     this.goodsinfo = [{
                        'productId': this.productsdetails.productId,
                        'productName': this.productsdetails.productName,
                        'productPrice': this.productsdetails.productPrice,
                        'categoryName':this.productsdetails.categoryName,
                        'title': this.productsdetails.productName,
                        'price': this.productsdetails.productPrice,
                     }]
                  }
                  //预售券
                  if (this.productsdetails.type == 'COUPON_PRESELL') {
                     this.marketingcontentlist = this.PreSalelist
                     this.PreSalelist.forEach(items => {
                        if (items.id == this.productsdetails.productId) {
                           this.goodsinfo = [{
                              'productId': this.productsdetails.productId,
                              'productName': this.productsdetails.productName,
                              'productPrice': this.productsdetails.productPrice,
                              'mainTitle': this.productsdetails.productName,
                              'presellPrice': this.productsdetails.productPrice,
                              'couponPresellProduct': {
                                 'productName': items.couponPresellProduct.productName,
                                 'price': this.productsdetails.productPrice
                              }
                           }]
                        }
                     })

                  }
                  this.formData.products = this.goodsinfo
                  // if (this.goodsinfo.length == 0){
                  //    this.$message.error('当前营销的预售券已过期或已失效，请重新选择！')
                  // }
               }
               this.formData.content = this.productsdetails.content
               this.formData.allSalesProduct = this.productsdetails.allSalesProduct
               this.productsdetails.awardRules.forEach(item=>{
                  if (item.hasOwnProperty('marketingCoupons')){
                     item.couponlist = item.marketingCoupons.filter(item=>{
                        return  item.level == 1
                     })
                     this.couponlistshow = true
                     item.couponlist1 = item.marketingCoupons.filter(item=>{
                        return  item.level == 2
                     })
                     this.couponlistshow1 = true
                     item.couponlist2 = item.marketingCoupons.filter(item=>{
                        return  item.level == 3
                     })
                     this.couponlistshow2 = true
                  }
               })
               this.couponData = this.productsdetails.awardRules
               this.couponData.forEach(item=>{
                  if (!item.hasOwnProperty('isPercentReferrer'))item.isPercentReferrer = 'YES'
                  if (!item.hasOwnProperty('isPercent2'))item.isPercent2 = 'YES'
                  if (!item.hasOwnProperty('isPercent1'))item.isPercent1 = 'YES'
               })
               if (this.productsdetails.isSeckillCommission == 'YES') this.formData.isSeckillCommission = true
               if (this.productsdetails.isSeckillCommission == 'NO') this.formData.isSeckillCommission = false
            }
         })
      },
      //选择营销分组
      marketingdig() {
         this.marketingdialogVisible = true
         this.$nextTick(() => {
            this.couponData.forEach(item => {
               this.getmarketinggrouplist.forEach(items => {
                  if (items.id == item.groupId) {
                     this.$refs.groupTypedataTable.toggleRowSelection(items, true);
                  }
               })

            })
         })
      },
      //营销类型切换时
      getgoodsinfo() {

         this.goodsinfo = []
         if (this.formData.type == 'HOTEL') {
            this.marketingcontentlist = this.roomypelist
         }
         if (this.formData.type == 'GOODS') {
            this.marketingcontentlist = this.goodslist
         }
         if (this.formData.type == 'COUPON_PRESELL') {
            this.marketingcontentlist = this.PreSalelist
         }

         this.$refs.dataTable.clearSelection()
      },

      //获取参加了营销活动的商品
      getmarketingproductslists() {
         const url = marketing.getmarketingproductslists
         const param = { hotelId:this.hotelInfo.id}
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.marketinggoodslist = res.records
               this.getPreSalePage()
               this.getroomtypelist()
               this.getGoodslist()
               if (this.$route.query.id) {
                  let _this=this
                  setTimeout(function()  {
                     _this.getmarketingproductsdetails()
                  }, 500);
               }
            }
         })
      },
      //禁选已经参加过营销商品
      selectable(row, index) {
         if (row.editshow === true) {
            return false;//禁用状态
         } else {
            return true;//非禁用状态
         }
      },
      //复选框组
      handleCheckedCitiesChange(value) {
         let checkedCount = value.length;
         this.checkAll = checkedCount === this.cities.length;
         if (value.indexOf('1') == -1){
            this.couponData.forEach(item=>{
               item.awardAmount1 = ''
               item.awardIntegral1 = ''
               item.isPercent1 = 'YES'

            })
         }
         if (value.indexOf('2') == -1){
            this.couponData.forEach(item=>{
               item.awardAmount2 = ''
               item.awardIntegral2 = ''
               item.isPercent2 =  'YES'
            })

         }
         if (value.indexOf('3') == -1){
            this.couponData.forEach(item=>{
               item.awardAmountReferrer = ''
               item.awardIntegralReferrer = ''
               item.isPercentReferrer = 'YES'
            })

         }
         this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },
      //删除选中的营销内容
      deleterow(val, i) {
         let list = []
         this.goodsinfo.splice(i, 1)
         this.formData.products.forEach((item,i)=>{
            if(item.productId == val.productId || val.id == item.productId){
               this.formData.products.splice(i,1)
            }
         })
         this.marketingcontentlist.forEach(item=>{
            if (item.id == val.productId || val.id == item.id){
               item.editshow = false
               if (this.$route.query.action == 'edit'){
                  this.radio = false

               }else {
                  this.$refs.dataTable.toggleRowSelection(item, false);
               }
            }
         })
         this.goodsinfo.forEach(item => {
            list.push(item.id)
         })
      },
      //营销内容切换
      changetype() {

         this.getgoodsinfo()
         this.marketingcontentlist.forEach(item => {
            this.$nextTick(() => {
               this.$refs.dataTable.toggleRowSelection(item, false);
            })
         })
      },
      //确定营销内容
      determinecontent(){
         this.checkgoodsinfo.forEach(item=>{
            this.goodsinfo.push(item)

         })
         let customArray = new CustomArray(this.goodsinfo)
         this.goodsinfo= customArray.unique()
         this.goodsinfo.forEach(item => {
            //房型
            if (this.formData.type == 'HOTEL') {
               this.formData.products.push({
                  'productId': item.id,
                  'productName': item.roomTypeName,
                  'productPrice': item.roomTypePrice
               })
            }
            //商品
            if (this.formData.type == 'GOODS') {
               this.formData.products.push({
                  'productId': item.id,
                  'productName': item.title,
                  'productPrice': item.price,
                  'categoryName':item.categoryName
               })
            }
            //预售券

            if (this.formData.type == 'COUPON_PRESELL' && this.$route.query.action == 'edit') {
               this.formData.products.push({
                  'productId': item.id,
                  'productName': item.mainTitle,
                  'productPrice': item.presellPrice
               })
            }
            if (this.formData.type == 'COUPON_PRESELL' && this.$route.query.action == 'add') {
               this.formData.products.push({
                  'productId': item.id,
                  'productName': item.mainTitle,
                  'productPrice': item.presellPrice / 100
               })
            }

         })
         this.emidtdialogVisible = false
         this.dialogVisible = false
         this.restgoodlist()
      },
      //营销内容表格单选框事件
      getCurrentRow(val) {
         if (this.$route.query.id) {
            this.goodsinfo = []
            val = JSON.stringify(val)
            this.goodsinfo.push(JSON.parse(val) )
            this.goodsinfo[0].presellPrice = this.goodsinfo[0].presellPrice/100

         } else {
            this.checkgoodsinfo = val
         }
      },
      //营销分组表格复选框事件
      getaddCurrentRow(val) {
         this.groupinglist = val
      },
      //确定所选分组
      ensurerow(){
         if (this.$route.query.action == 'edit'){
            this.couponData.forEach(item => {
               if (!item.hasOwnProperty('couponlist'))item.couponlist = []
               if (!item.hasOwnProperty('couponlist1'))item.couponlist1 = []
               if (!item.hasOwnProperty('couponlist2'))item.couponlist2 = []
               this.groupinglist.forEach((items) => {
                  if (item.groupId == items.id) {
                     items.awardAmount1 = item.awardAmount1 || ''
                     items.awardIntegral1 = item.awardIntegral1 || ''
                     items.awardAmount2 = item.awardAmount2 || ''
                     items.awardIntegral2 = item.awardIntegral2 || ''
                     items.awardAmountReferrer = item.awardAmountReferrer || ''
                     items.awardIntegralReferrer = item.awardIntegralReferrer || ''
                     items.couponlist = item.couponlist || ''
                     items.couponlist1 = item.couponlist1 || ''
                     items.couponlist2 = item.couponlist2 || ''
                     items.groupId = item.groupId
                     items.id = item.id
                     items.isPercentReferrer = item.isPercentReferrer
                     items.isPercent2 = item.isPercent2
                     items.isPercent1 = item.isPercent1
                     if (!items.hasOwnProperty('isPercentReferrer'))items.isPercentReferrer = 'YES'
                     if (!items.hasOwnProperty('isPercent2'))items.isPercent2 = 'YES'
                     if (!items.hasOwnProperty('isPercent1'))items.isPercent1 = 'YES'
                  }

               })
            })
            this.groupinglist.forEach(item=>{
               if (!item.hasOwnProperty('isPercentReferrer'))item.isPercentReferrer = 'YES'
               if (!item.hasOwnProperty('isPercent2'))item.isPercent2 = 'YES'
               if (!item.hasOwnProperty('isPercent1'))item.isPercent1 = 'YES'
            })
            this.couponData = this.groupinglist
         }else{
            this.groupinglist.forEach(item=>{
               if (!item.hasOwnProperty('isPercentReferrer'))item.isPercentReferrer = 'YES'
               if (!item.hasOwnProperty('isPercent2'))item.isPercent2 = 'YES'
               if (!item.hasOwnProperty('isPercent1'))item.isPercent1 = 'YES'
            })
            this.couponData = this.groupinglist
         }
         this.marketingdialogVisible = false
      },
      //获取下标
      getIndex(val, i, type) {
         this.marketingcouponslistdialog = true
         this.tableindex = i
         this.awardtype = type
         val = JSON.stringify(val)
         val = JSON.parse(val)
         if (type == '一级') {
            if (this.couponData[this.tableindex].hasOwnProperty('couponlist') && this.couponData[this.tableindex].couponlist.length != 0) {
               this.$nextTick(() => {
                  this.couponslist.forEach(item=> {
                     val.couponlist.forEach(items=>{
                        if (items.hasOwnProperty('couponId')){
                           if (items.couponId == item.id){
                              this.$nextTick(() => {
                                 this.$refs.couponslistdataTable.toggleRowSelection(item, true);
                              })
                           }else{
                              this.$nextTick(() => {
                                 this.$refs.couponslistdataTable.toggleRowSelection(item, false);
                              })
                           }
                        }else{

                           if (items.id != item.id){
                              this.$nextTick(() => {
                                 this.$refs.couponslistdataTable.toggleRowSelection(items, false);
                              })
                           }
                        }
                     })

                  })

               })
            } else {
               this.$nextTick(() => {
                  this.couponslist.forEach(item => {
                     this.$refs.couponslistdataTable.toggleRowSelection(item, false);
                  })
               })
            }
         }
         if (type == '二级') {
            if (this.couponData[this.tableindex].hasOwnProperty('couponlist1') && this.couponData[this.tableindex].couponlist1.length != 0) {
               this.$nextTick(() => {
                  this.couponslist.forEach(item=> {
                     val.couponlist1.forEach(items=>{
                        if (items.hasOwnProperty('couponId')){
                           if (items.couponId == item.id){
                              this.$nextTick(() => {
                                 this.$refs.couponslistdataTable.toggleRowSelection(item, true);
                              })
                           }else{
                              this.$nextTick(() => {
                                 this.$refs.couponslistdataTable.toggleRowSelection(item, false);
                              })
                           }
                        }else{
                           if ( items.id == item.id){
                              this.$refs.couponslistdataTable.toggleRowSelection(item, true);
                           }
                        }
                     })

                  })

               })
            }else {
               this.$nextTick(() => {
                  this.couponslist.forEach(item => {
                     this.$refs.couponslistdataTable.toggleRowSelection(item, false);
                  })
               })
            }
         }
         if (type == '被推荐') {
            if (this.couponData[this.tableindex].hasOwnProperty('couponlist2') && this.couponData[this.tableindex].couponlist2.length != 0) {
               this.$nextTick(() => {
                  this.couponslist.forEach(item=> {
                     val.couponlist2.forEach(items=>{
                        if (items.hasOwnProperty('couponId')){
                           if (items.couponId == item.id){
                              this.$nextTick(() => {
                                 this.$refs.couponslistdataTable.toggleRowSelection(item, true);
                              })
                           }
                        }else{
                           if ( items.id == item.id){
                              this.$refs.couponslistdataTable.toggleRowSelection(items, true);
                           }
                        }
                     })

                  })
               })
            }else {
               this.$nextTick(() => {
                  this.couponslist.forEach(item => {
                     this.$refs.couponslistdataTable.toggleRowSelection(item, false);
                  })
               })
            }
         }
      },
      //删除优惠券
      deletercoupon(row,val,index){
         if(row[index].hasOwnProperty('ruleId')){
            this.formData.couponIds.push(val.id)
         }
         row.splice(index,1)
         this.couponlistshow = false
         this.couponlistshow1 = false
         this.couponlistshow2 = false
         this.couponlistshow = true
         this.couponlistshow1 = true
         this.couponlistshow2 = true

      },
      //选择优惠券列表
      getaddCouponRow(val) {

         if (this.$route.query.id){
            val = JSON.stringify(val)
            if (this.awardtype == '一级') {
               this.couponlistshow = false
               let data = JSON.parse(val);
               data.forEach((item,i)=>{
                  item.couponName = item.mainTitle;
                  item.level = 1
               })
               if (this.couponData[this.tableindex].couponlist.length == 0){
                  this.couponData[this.tableindex].couponlist = data

               }else{
                  data.forEach((items,i)=>{
                     this.couponData[this.tableindex].couponlist.forEach(item=>{
                        if(item.hasOwnProperty('ruleId')) {
                           if (items.id == item.couponId) {
                              data.splice(i, 1)
                              data.push(item)
                           }
                        }else{
                           if (items.id == item.id) {
                              data.splice(i, 1)
                              data.push(item)
                           }
                        }
                     })
                  })
                  this.couponData[this.tableindex].couponlist = data
               }
               this.couponData[this.tableindex].couponlist= new CustomArray(this.couponData[this.tableindex].couponlist).unique()
               this.couponlistshow = true
            }
            if (this.awardtype == '二级') {
               this.couponlistshow1 = false
               let data = JSON.parse(val);
               data.forEach((item,i)=>{
                  item.couponName = item.mainTitle;
                  item.level = 2
               })
               if (this.couponData[this.tableindex].couponlist1.length == 0){
                  this.couponData[this.tableindex].couponlist1 = data
               }else{
                  data.forEach((items,i)=>{

                     this.couponData[this.tableindex].couponlist1.forEach(item=>{
                        if(item.hasOwnProperty('ruleId')){
                           if (items.id == item.couponId) {
                              data.splice(i, 1)
                              data.push(item)
                           }
                        }else{
                           if (items.id == item.id) {
                              data.splice(i, 1)
                              data.push(item)
                           }
                        }
                     })

                  })
                  this.couponData[this.tableindex].couponlist1 = data
               }
               this.couponData[this.tableindex].couponlist1 = new CustomArray(this.couponData[this.tableindex].couponlist1).unique()
               this.couponlistshow1 = true

            }
            if (this.awardtype == '被推荐') {
               this.couponlistshow2 = false
               let data = JSON.parse(val);
               data.forEach((item,i)=>{
                  item.couponName = item.mainTitle;
                  item.level = 3
               })
               if (this.couponData[this.tableindex].couponlist2.length == 0){
                  this.couponData[this.tableindex].couponlist2 = data

               }else{
                  data.forEach((items,i)=>{
                     this.couponData[this.tableindex].couponlist2.forEach(item=>{
                        if(item.hasOwnProperty('ruleId')){
                           if (items.id == item.couponId){
                              data.splice(i,1)
                              data.push(item)
                           }
                        }else{
                           if (items.id == item.id) {
                              data.splice(i, 1)
                              data.push(item)
                           }
                        }
                     })

                  })
                  this.couponData[this.tableindex].couponlist2 = data
               }
               this.couponData[this.tableindex].couponlist2 = new CustomArray(this.couponData[this.tableindex].couponlist2).unique()
               this.couponlistshow2 = true
            }
         }else{
            val = JSON.stringify(val)
            if (this.awardtype == '一级') {
               this.couponlistshow = false
               this.couponData[this.tableindex].couponlist = JSON.parse(val);
               this.couponData[this.tableindex].couponlist.forEach(item=>{
                  item.couponName = item.mainTitle;
                  item.level = 1
               })
               this.couponlistshow = true
            }
            if (this.awardtype == '二级') {
               this.couponlistshow1 = false
               this.couponData[this.tableindex].couponlist1 = JSON.parse(val);
               this.couponData[this.tableindex].couponlist1.forEach(item=>{
                  item.couponName = item.mainTitle;
                  item.level = 2
               })
               this.couponlistshow1 = true
            }
            if (this.awardtype == '被推荐') {
               this.couponlistshow2 = false
               this.couponData[this.tableindex].couponlist2 = JSON.parse(val);
               this.couponData[this.tableindex].couponlist2.forEach(item=>{
                  item.couponName = item.mainTitle;
                  item.level = 3
               })
               this.couponlistshow2 = true
            }
         }

      },
      //删除奖励规则列表
      deltablerow(val, i) {
         if (val.hasOwnProperty('couponlist')) val.couponlist = []
         if (val.hasOwnProperty('awardAmount1')) val.awardAmount1 = ''
         if (val.hasOwnProperty('awardIntegral1')) val.awardIntegral1 = ''

         if (val.hasOwnProperty('couponlist1')) val.couponlist1 = []
         if (val.hasOwnProperty('awardAmount2')) val.awardAmount2 = ''
         if (val.hasOwnProperty('awardIntegral2')) val.awardIntegral2 = ''

         if (val.hasOwnProperty('couponlist2')) val.couponlist2 = []
         if (val.hasOwnProperty('awardAmountReferrer')) val.awardAmountReferrer = ''
         if (val.hasOwnProperty('awardIntegralReferrer')) val.awardIntegralReferrer = ''

         this.couponData.splice(i, 1)
         this.formData.awardRuleIds.push(val.id)
         this.$nextTick(() => {
            this.getmarketinggrouplist.forEach(item => {
               if (val.id == item.id) {
                  this.$refs.groupTypedataTable.toggleRowSelection(item, false);
               }
            })
         })
      },

      //关闭弹窗
      determine() {
         if (this.goodsinfo == '') {
            this.$message.error('请选择营销内容')
         } else {
            this.chooseshow = true
            this.dialogVisible = false
            this.emidtdialogVisible = false
         }

      },
      changeunit(val,i,type){
         if(type == 'isPercent1'){
            this.couponData[i].isPercent1 = val
            this.couponData[i].awardAmount1 = ''
         }
         if(type == 'isPercent2'){
            this.$nextTick(()=>{
               this.couponData[i].isPercent2 = val
               this.couponData[i].awardAmount2 = ''
            })
         }
         if(type == 'isPercentReferrer'){
            this.$nextTick(()=>{
               this.couponData[i].isPercentReferrer = val
               this.couponData[i].awardAmountReferrer = ''

            })
         }
         this.couponData = Object.assign([],this.couponData)
      },
      //一级推荐人奖励值校验
      leaveberChange1(val, index,type) {
         if(type == 'YES'){
            if (Number(val) > 15) {
               this.$message.error('一级推荐人超过奖励最大限制为15%')
               this.couponData[index].awardAmount1 = 15
            }
         }else{
            let reg =  /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
            if (!reg.test(val)){
               this.couponData[index].awardAmount1 = ''
               this.$message.error('输入金额有误,只能保留两位小数')
            }
         }

      },

      //二级推荐人奖励值校验
      leaveberChange2(val, index,type) {
         if(type == 'YES') {
            if (Number(val) > 10) {
               this.$message.error('二级推荐人超过奖励最大限制为10%')
               this.couponData[index].awardAmount2 = 10
            }
         }else {
            let reg =  /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
            if (!reg.test(val)){
               this.couponData[index].awardAmount2 = ''
               this.$message.error('输入金额有误,只能保留两位小数')
            }
         }
      },

      //被推荐人奖励值校验
      leaveberChange3(val, index,type) {
         if(type == 'YES') {
            if (Number(val) > 3) {
               this.$message.error('被推荐人超过奖励最大限制为3%')
               this.couponData[index].awardAmountReferrer = 3
            }
         }else{
            let reg =  /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
            if (!reg.test(val)){
               this.couponData[index].awardAmount1 = ''
               this.$message.error('输入金额有误,只能保留两位小数')
            }
         }
      },

      //选择有效期区间
      choosevalidtime() {
         this.formData.beginTime = this.validDate[0]
         this.formData.endTime = this.validDate[1]
      },

      // 预售卷列表
      getPreSalePage() {
         const url = preSale.preSalePage
         let param = {
            page: this.page,
            limit: this.limit,
            hotelId: this.hotelInfo.id,
            status: 'PROCESSING',
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.total = res.total
               this.PreSalelist = res.records
               this.PreSalelist.map(item => {
                  item.editshow = false
               })
               this.marketinggoodslist.forEach(item => {
                  this.PreSalelist.forEach(items => {
                     if (item.productId == items.id) {
                        items.editshow = true
                     }
                  })
               })
            }
            if (this.formData.type == 'GOODS' || this.productsdetails.type == 'GOODS') {
               this.marketingcontentlist = this.PreSalelist
            }

         })
      },

      // 获取营销分组列表
      getMarketinggroup() {
         const url = marketing.getmarketinggroup
         const param = {hotelId:this.hotelInfo.id}
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.getmarketinggrouplist = res.records

            }
         })
      },

      //获取房型列表
      getroomtypelist() {
         if(this.hotelInfo.isEbooking == 'YES'){
            this.roomTypeSource = 'EBOOKING'
         }else{
            this.roomTypeSource = 'PMS'
         }
         const url = pms.pmsRoomTypes
         const param = {
            hotelId: this.hotelInfo.id,
            roomTypeSource:this.roomTypeSource
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.roomypelist = res.records
               this.roomypelist.map(item => {
                  item.editshow = false
               })
               this.marketinggoodslist.forEach(item => {
                  this.roomypelist.forEach((items, i) => {
                     if (item.productId == items.id) {
                        items.editshow = true
                     }
                  })

               })
            }
            if (this.$route.query.action == 'add' || this.productsdetails.type =='HOTEL') {
               this.marketingcontentlist = this.roomypelist
            }

         })
      },
      //重置商品类表
      restgoodlist(){
         this.title = ''
         this.getGoodslist()
      },
      // 获取商品列表
      getGoodslist() {
         const url = urlObj.getGoodsPaging + `?page=${this.page}&limit=${this.limit}`
         const param = {
            status: this.status,
            title: this.title,
            hotelId:this.hotelInfo.id
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.loading = false
               this.total = res.total
               this.goodslist = res.records
               this.goodslist.forEach(item => {
                  item.coverPath = item.coverPath && window.getResourcesHost() + item.coverPath
                  item.editshow = false
               })
               this.marketinggoodslist.forEach(item => {
                  this.goodslist.forEach((items, i) => {
                     if (item.productId == items.id) {
                        items.editshow = true
                     }
                  })

               })

               if (this.formData.type == 'GOODS' || this.productsdetails.type == 'GOODS') {
                  this.marketingcontentlist = this.goodslist
               }
               if (this.goodsinfo.length != 0){
                  this.goodsinfo.forEach(item=>{
                     this.marketingcontentlist.forEach(items=>{
                        if (item.id == items.id){
                           this.checkgoodsinfo = []
                           this.$nextTick(()=>{
                              this.$refs.dataTable.toggleRowSelection(items,true);
                           })

                        }
                     })
                  })
               }
            }
         })
      },

      // 获取优惠券列表
      getCoupons() {
         const url = coupon.couponPage + `?limit=${this.limit}&page=${this.page}`
         const param = {
            hotelId: this.hotelInfo.id,
            status: 'PROCESSING',
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.total = res.total
               this.couponslist = res.records.map(item => {
                  item.count = 1
                  if (item.couponCollection != undefined) {
                     item.couponCollection = item.couponCollection + 1
                  }
                  if (item.usage != undefined) {
                     item.usage = item.usage + 1
                  }
                  return item
               })
            }
         })
      },

      //提交
      submit() {
         if (this.formData.name == "") {
            this.$message.error('请输入商品名称')
            return
         }
         if (this.formData.allSalesProduct == "NO" && this.formData.products == undefined && this.formData.products.length != 0) {
            this.$message.error('请选择营销内容')
            return
         }
         if (this.couponData.length == 0) {
            this.$message.error('请添加营销分组')
            return
         }

         if (this.formData.timeType == 'DATE_RANGE' && this.distributionTime == '') {
            this.$message.error('请选择分销时段')
            return
         }

         if (this.formData.timeType == 'EVERY_DAY') {
            this.formData.beginTime = ''
            this.formData.endTime = ''

         }
         if (this.formData.isSeckillCommission == true) this.formData.isSeckillCommission = 'YES'
         if (this.formData.isSeckillCommission == false) this.formData.isSeckillCommission = 'NO'
         if (this.formData.allSalesProduct == 'YES') {
            this.formData.product = []
            delete this.formData.products
         }else{
            delete this.formData.product
         }

         this.formData.commissionLevel = this.checkedCities.join(',')
         if (this.$route.query.action == 'add'){
            this.couponData.forEach(item => {
               item.groupId = item.id
               delete item.id
            })
         }else{
            this.couponData.forEach((item,index) => {
               if (!item.hasOwnProperty('groupId')){
                  this.couponData[index].groupId = this.couponData[index].id
                  delete this.couponData[index].id
               }else{
                  delete this.couponData[index].groupId
               }

            })
         }
         this.formData.awardRules = this.couponData
         this.formData.awardRules.forEach(item=>{
            item.list = []
         })
         this.formData.awardRules.forEach((item, index) => {

            if (item.couponlist == undefined) {
               item.couponlist = []
            }
            if (item.couponlist1 == undefined) {
               item.couponlist1 = []
            }
            if (item.couponlist2 == undefined) {
               item.couponlist2 = []
            }
            item.couponlist.forEach(item=>{
               this.formData.awardRules[index].list.push(item)
            })
            item.couponlist1.forEach(item=>{
               this.formData.awardRules[index].list.push(item)
            })
            item.couponlist2.forEach(item=>{
               this.formData.awardRules[index].list.push(item)
            })
            this.formData.awardRules[index].list = this.formData.awardRules[index].list.filter(item => {
               return item != undefined && item.length != 0
            })

            if(item.awardAmount1 == '' || !item.hasOwnProperty('awardAmount1')){
               delete item.awardIntegral1
               delete item.isPercent1
            }
            if(item.awardAmount2 == '' || !item.hasOwnProperty('awardAmount2')){
               delete item.awardAmount2
               delete item.isPercent2
            }
            if (item.awardAmountReferrer == '' || !item.hasOwnProperty('awardAmountReferrer')){
               delete item.awardAmountReferrer
               delete item.isPercentReferrer
            }
            item.marketingCoupons = []
            this.formData.awardRules[index].list.forEach(items => {
               if (!items.hasOwnProperty('ruleId')){
                  item.marketingCoupons.push({
                     couponName: items.couponName,
                     couponId: items.id,
                     level: items.level,
                     count: items.count,
                  })
               }else{
                  item.id = items.ruleId
                  item.marketingCoupons.push({
                     couponName: items.couponName,
                     couponId: items.couponId,
                     id:items.id,
                     level: items.level,
                     count: items.count,
                  })
               }

            })
            delete item.couponlist
            delete item.couponlist1
            delete item.couponlist2
            delete item.createTime
            delete item.createOperator
            delete item.updateTime
            delete item.updateOperator
            delete item.list

         })

         let param = this.formData
         if (this.$route.query.id) {
            this.url = marketing.editmarketingproduct
         } else {
            this.url = marketing.addmarketingproduct;
         }
         this.$axios.post(this.url, param, 'json').then((res) => {
            if (res.status == 200) {
               this.$message({
                  message: "创建成功!",
                  type: "success",
               });
               this.$router.push("/marketing_products");
            }
         })

      },
      //管理分组
      jump() {
         // window.open('https://hosmart.hored.net/marketing_group')
         this.$router.push({path: '/marketing_group'})
      },
      //选择时间后
      choosetime() {
         this.formData.beginTime = this.distributionTime[0] + ' 00:00:01'
         this.formData.endTime = this.distributionTime[1] + ' 23:59:59'
      },

      //上传海报
      handleChangeposter(file) {
         this.file = file.raw
         const url = urlObj.uploadDetailFile;
         let param = new FormData();
         param.append("file", this.file); // 文件
         param.append('bizId','default')
         param.append('module','event')
         param.append('menu','distributor')
         param.append('func','image')
         param.append('category','image')
         param.append('isThumb',true)
         this.$axios.post(url, param, 'file')
               .then((res) => {
                  this.formData.image = window.getResourcesHost() + res.records.location + '/'+ res.records.formatName
                  this.isEcho = false
               });
         this.$message.success('上传成功')

      },
      // 点击图片查看大图
      handlePictureCardPreview(file){
         this.formData.image = file.url;
         this.imgdialogVisible = true;
      },

   },
}
</script>

<style scoped lang="scss">

.radio-box {
   ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: linear-gradient(90deg, #006FFF, #189AFF) !important;
   }
}

.pre-sale-main {
   padding: 20px;
   background: #fff;

   .right-form > .el-row {
      margin-bottom: 10px
   }

   .right-form {
      padding: 0 0 0 40px;
      width: 100%;

      .tit {
         font-size: 18px;
         margin-bottom: 30px
      }

      .input-box {
         line-height: 28px;
         overflow: hidden;

         .name {
            width: 100px;
            text-align: right;
            float: left;

            i {
               color: #ff0000;
               font-size: 18px;
               margin-right: 4px
            }
         }

         .flex {
            display: flex;
            display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
            display: -moz-box; /* Firefox 17- */
            display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
            display: -moz-flex; /* Firefox 18+ */
            display: -ms-flexbox; /* IE 10 */
            display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
         }

         .content {
            float: left;
            margin-left: 15px;
         }

         .radio {
            padding: 7px 0
         }

         .m-left-right {
            margin: 0 10px
         }

         .img-list {
            margin-top: 10px;

            .el-row {
               margin: 10px 10px 0 0
            }
         }

         /* 标签 */
         .el-tag + .el-tag {
            margin-left: 10px;
         }

         .button-new-tag {
            margin-left: 10px;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
         }

         .input-new-tag {
            width: 90px;
            margin-left: 10px;
            vertical-align: bottom;
         }
      }
   }
}

.table-box {
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 1;
   line-clamp: 1;
   -webkit-box-orient: vertical;
}

.tips {
   opacity: 0.5;
}

.img-list img {
   width: 100px;
   height: 100px;
}

.textinpt {
   min-width: 70px;
   width: 90px;
   border: 1px solid #dedede;
   padding: 3px 0;
   -webkit-border-radius: 3px; //适配以webkit为核心的浏览器(chrome、safari等)
   -moz-border-radius: 3px; //适配firefox浏览器
   -ms-border-radius: 3px; //适配IE浏览器
   -o-border-radius: 3px; //适配opera浏览器
   border-radius: 3px;
}

.textinpt:focus {
   outline: 1px solid #51c9ff;
   background: rgba(3, 16, 28, 0);
   padding: 1px;
   -webkit-border-radius: 3px; //适配以webkit为核心的浏览器(chrome、safari等)
   -moz-border-radius: 3px; //适配firefox浏览器
   -ms-border-radius: 3px; //适配IE浏览器
   -o-border-radius: 3px; //适配opera浏览器
   border-radius: 3px;
}

.delicon {
   position: absolute;
   top: 0;
   right: 0;
   color: red;
   font-weight: 900;
   font-size: 20px;
   cursor: pointer;
   line-height: 10px;
}

.img-box {
   display: flex;
   margin-bottom: 20px;
   display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
   display: -moz-box; /* Firefox 17- */
   display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
   display: -moz-flex; /* Firefox 18+ */
   display: -ms-flexbox; /* IE 10 */
   display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
}

.poster {
   width: 120px;
   height: 160px;
   border-radius: 10px;
}

.dialog-bottombox {
   text-align: center;
   padding: 20px;
}

.table-item {
   width: 33%;
   float: left;
   text-align: center;
}

.table-img {
   width: 100px;
   height: 60px;
   border-radius: 5px;
   float: left;
}

.goods-title {
   float: left;
   width: 160px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   line-clamp: 1;
   -webkit-box-orient: vertical;
   margin-top: 25px;
   padding-left: 20px;
}
.tipe{
   padding-top: 5px;
   opacity: 0.5;
}

/deep/ .el-form-item .el-form-item {
   margin-bottom: 22px;
}

/deep/ .el-dialog__header {
   padding: 20px;
   padding-bottom: 10px;
   font-size: 17px;
   color: #fff;
   background: #2d8cf0;
   border-radius: 10px 10px 0 0;
}

/deep/ .el-dialog__title {
   line-height: 36px;
   font-size: 17px;
   color: #fff;
}

/deep/ .el-table th.gutter {
   /*
   * 解决element-ui 表格篡位的问题 👇
   */
   display: table-cell !important;
}

/deep/ .el-dialog {
   position: relative;
   margin: 0 auto 50px;
   background: #FFFFFF;
   border-radius: 10px;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   width: 50%;
}

/deep/ .el-dialog__body {
   padding: 30px 20px;
   color: #606266;
   font-size: 14px;
   word-break: break-all;
   margin: 0 30px;
}

/deep/ .el-table th.is-leaf, .el-table td {
   background: #F5F7FA;
}

.el-table th > .cell {
   font-size: 17px;
}

/deep/ .el-table--fit {
   border-right: 0;
   border-bottom: 0;
}

/deep/ .cell label > .el-radio__label {
   display: none
}

/deep/ .el-radio-button__inner {
   margin: 0 10px;
   border-radius: 4px !important;
   border: 1px solid #dedede;
}

.avatar-uploader .el-upload {
   border: 1px dashed #d9d9d9;
   border-radius: 6px;
   cursor: pointer;
   position: relative;
   overflow: hidden;
}

.avatar-uploader .el-upload:hover {
   border-color: #409EFF;
}

.avatar-uploader-icon {
   font-size: 28px;
   color: #8c939d;
   width: 120px;
   height: 160px;
   line-height: 160px;
   border: 1px dashed #dedede;
   text-align: center;
}

</style>
